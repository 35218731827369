@charset "UTF-8";
/* stylelint-disable order/order */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.text-field {
  position: relative;
  display: block;
  text-align: left;
}
.text-field input {
  display: block;
  width: 100%;
  height: 1.6666666667vw;
  padding: 0 0.5208333333vw;
  line-height: 1.1458333333vw;
  border: none;
  outline: none;
  font-size: 0.9895833333vw;
  font-family: "narkiss-regular", sans-serif;
  color: #fff;
  border-bottom: 1px solid #fff;
  background-color: inherit;
}
@media only screen and (max-width: 767px) {
  .text-field input {
    height: 9.84375vw;
    padding: 0 1.875vw;
    line-height: 6.25vw;
    font-size: 4.6875vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field input {
    height: 3.90625vw;
    padding: 0 0.9375vw;
    font-size: 1.875vw;
  }
}
.text-field input.inline {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #707070;
}
.text-field .mobile-phone {
  direction: ltr;
  text-align: right;
}
.text-field textarea {
  display: block;
  width: 100%;
  padding: 0.5208333333vw 0.5208333333vw;
  border-radius: 4px;
  background-color: #125da7;
  border: 1px solid #fff;
  outline: none;
  font-size: 0.9895833333vw;
  line-height: 1.09375vw;
  font-family: "narkiss-regular", sans-serif;
  color: #fff;
  resize: none;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field textarea {
    height: 5.46875vw;
    padding: 1.875vw 1.875vw;
    font-size: 1.875vw;
  }
}
@media only screen and (max-width: 767px) {
  .text-field textarea {
    padding: 1.875vw 1.875vw;
    line-height: 4.375vw;
    border-radius: 0.46875vw;
    font-size: 4.0625vw;
  }
}
.text-field textarea.small {
  height: 5.3125vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field textarea.small {
    height: 9.375vw;
  }
}
@media only screen and (max-width: 767px) {
  .text-field textarea.small {
    height: 23.75vw;
  }
}
.text-field textarea.big {
  height: 11.5625vw;
}
@media only screen and (max-width: 767px) {
  .text-field textarea.big {
    height: 46.09375vw;
  }
}
.text-field .placeholder {
  position: absolute;
  top: -0.2604166667vw;
  right: 0.4166666667vw;
  font-family: "narkiss-regular", sans-serif;
  font-weight: 100;
  font-size: 0.9375vw;
  letter-spacing: 0.48px;
  text-align: right;
  line-height: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder {
    top: 2.03125vw;
    right: 1.875vw;
    padding-left: 1.875vw;
    font-size: 4.6875vw;
    letter-spacing: 0.69px;
    opacity: 0.8;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .placeholder {
    top: 0.78125vw;
    right: 1.09375vw;
    padding-left: 0.78125vw;
    font-size: 2.1875vw;
    line-height: 1;
  }
}
.text-field .placeholder span {
  font-size: 0.78125vw;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-field .placeholder span {
    font-size: 1.71875vw;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder span {
    font-size: 4.21875vw;
    letter-spacing: 0.096875vw;
  }
}
.text-field .placeholder.hide {
  display: none;
}
.text-field .placeholder.for-textarea {
  top: 0.3645833333vw;
  right: 0.8333333333vw;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder.for-textarea {
    top: 2.5vw;
    right: 2.1875vw;
    line-height: 6.25vw;
  }
}