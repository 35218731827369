@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.socials {
  position: relative;
  z-index: 51;
  display: flex;
  height: 100%;
  cursor: pointer;
}
.socials.hide {
  display: none;
}
.socials .social-button {
  position: relative;
  display: inline-block;
  width: 37px;
  margin-right: 18px;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .socials .social-button {
    width: 4.21875vw;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button {
    width: 37px;
    margin: 0 10px;
  }
}
.socials .social-button:first-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .socials .social-button:first-child {
    margin: 0 10px;
  }
}
.socials .social-button .hint {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 8px;
  transform: translate(-50%, 15px);
  background-color: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
  font-size: 14px;
  border-radius: 8px;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .socials .social-button .hint {
    top: 50%;
    left: 100%;
    transform: translate(15px, -50%);
  }
}
.socials .social-button .hint::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, 0);
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
}
@media only screen and (max-width: 767px) {
  .socials .social-button .hint::after {
    top: 50%;
    right: 100%;
    bottom: initial;
    left: initial;
    transform: translate(0, -50%);
    border-width: 6px 6px 6px 0;
    border-color: transparent rgba(0, 0, 0, 0.9) transparent transparent;
  }
}
.socials .social-button svg {
  width: 100%;
  height: auto;
}
.socials .social-button img {
  width: 100%;
}
.socials .social-button:hover {
  opacity: 0.8;
}
.socials .social-button:hover .hint {
  opacity: 1;
}
.socials .overflowWrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap {
    display: flex;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 767px) {
  .socials.minified {
    margin-right: 2.265625vw;
  }
}
.socials.minified.open .overflowWrap {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
@media only screen and (max-width: 767px) {
  .socials.minified.open .overflowWrap {
    position: absolute;
    opacity: 1;
  }
}
.socials.minified .overflowWrap {
  position: relative;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .socials.minified .overflowWrap {
    position: absolute;
    top: 45px;
    left: 0;
    display: flex;
    width: 59px;
    height: 212px;
    flex-direction: column-reverse;
    justify-content: space-around;
    transition: all 0.3s ease;
    background: #22303b;
  }
}
.socials.minified .toggleBtn {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-left: 14px;
  overflow: visible;
  border-radius: 50%;
  transition: 0.3s;
}
.socials.minified .toggleBtn.open {
  width: 30px;
  height: 30px;
}
.socials.minified .toggleBtn svg {
  width: 100% !important;
  height: 100% !important;
}