@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.checkbox {
  position: relative;
  display: flex;
  margin-top: -0.2083333333vw;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .checkbox {
    align-items: flex-start;
  }
}
.checkbox input {
  display: none;
}
.checkbox p {
  font-size: 0.78125vw;
  line-height: 0.9895833333vw;
  letter-spacing: 0.83px;
  color: #fff;
  font-family: "narkiss-regular", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .checkbox p {
    font-size: 1.71875vw;
    line-height: 2.8125vw;
  }
}
@media only screen and (max-width: 767px) {
  .checkbox p {
    width: 79.6875vw;
    font-family: "narkiss-light", sans-serif;
    font-size: 3.984375vw;
    line-height: 5.78125vw;
  }
}
.checkbox p a {
  text-decoration: underline;
  color: #fff;
}
.checkbox p span {
  font-family: "Open Sans", sans-serif;
}
.checkbox p a {
  text-decoration: underline;
}
.checkbox p a:hover {
  text-decoration: none;
}
.checkbox .control {
  position: relative;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-left: 9px;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
}
@media only screen and (max-width: 767px) {
  .checkbox .control {
    width: 4.6875vw;
    height: 5vw;
    min-width: 4.6875vw;
    min-height: 5vw;
    margin-top: 0.78125vw;
    margin-left: 2.34375vw;
    border-radius: 0.46875vw;
  }
}
.checkbox .control::before {
  content: "";
  position: absolute;
  top: 49%;
  left: 52%;
  display: none;
  width: 50%;
  height: 30%;
  border: 2px solid #fff;
  border-right: 0;
  border-top: 0;
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media only screen and (max-width: 767px) {
  .checkbox .control::before {
    border: 0.3125vw solid #fff;
    border-right: 0;
    border-top: 0;
  }
}
.checkbox.active .control::before {
  display: block;
}