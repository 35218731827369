@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.shares-screen {
  position: relative;
  width: 100%;
  padding-bottom: 218px;
}
@media only screen and (max-width: 767px) {
  .shares-screen {
    height: 100%;
    min-height: calc(100vh - 60px);
    padding-bottom: 0;
  }
}
.shares-screen .top-section {
  position: relative;
  height: 377px;
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section {
    height: 235px;
  }
}
.shares-screen .top-section .top-section-wrapper {
  position: relative;
  width: 600px;
  height: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper {
    width: 100%;
    padding-top: 14px;
    padding-right: 25px;
    padding-left: 25px;
  }
}
.shares-screen .top-section .top-section-wrapper .bee-logo {
  display: none;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .bee-logo {
    display: block;
    width: 121px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
}
.shares-screen .top-section .top-section-wrapper .bee-logo img {
  display: block;
  width: 100%;
}
.shares-screen .top-section .top-section-wrapper .head-title {
  position: absolute;
  top: 121px;
  right: 86px;
  z-index: 3;
  width: 405px;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .head-title {
    position: relative;
    top: auto;
    right: auto;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
}
.shares-screen .top-section .top-section-wrapper .head-title span {
  display: inline-block;
  padding: 0 4px;
  font-size: 93.3px;
  line-height: 0.71;
  background-color: #fff;
  font-family: "almoni-demibold", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .head-title span {
    font-size: 65px;
    line-height: 0.74;
  }
}
.shares-screen .top-section .top-section-wrapper .head-title span:last-child {
  margin-top: 3px;
}
.shares-screen .top-section .top-section-wrapper .text {
  position: absolute;
  top: 198px;
  left: -130px;
  width: 492px;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .text {
    position: relative;
    top: auto;
    left: 0;
    z-index: 2;
    width: 100%;
    padding-top: 7px;
  }
}
.shares-screen .top-section .top-section-wrapper .text p {
  font-size: 28px;
  line-height: 30px;
  font-family: "almoni-medium", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .text p {
    font-size: 30px;
    line-height: 30px;
  }
}
.shares-screen .top-section .top-section-wrapper .bee-animation {
  position: absolute;
  top: -2px;
  right: -153px;
  width: 1400px;
  height: 300px;
}
@media only screen and (max-width: 767px) {
  .shares-screen .top-section .top-section-wrapper .bee-animation {
    position: absolute;
    top: 23px;
    right: -443px;
    z-index: 1;
    width: 1400px;
    height: 300px;
  }
}
.shares-screen .recommendations {
  width: 100%;
  max-width: 629px;
  margin: -12px auto 0;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations {
    padding: 0 16px 90px;
    padding-top: 15px;
    margin: 0 auto;
  }
}
.shares-screen .recommendations .recommendation {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
}
.shares-screen .recommendations .recommendation-item {
  display: flex;
  align-items: flex-start;
}
.shares-screen .recommendations .recommendation-item-image {
  width: 98px;
  height: 125px;
  min-width: 98px;
  margin-left: 16px;
  flex-shrink: 0;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations .recommendation-item-image {
    margin-left: 12px;
    flex-shrink: 0;
  }
}
.shares-screen .recommendations .recommendation-item-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shares-screen .recommendations .recommendation-item-info {
  width: 291px;
  min-width: 291px;
  padding-top: 1px;
  flex-grow: 1;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations .recommendation-item-info {
    width: auto;
    min-width: inherit;
    padding-top: 0;
    flex-grow: 1;
  }
}
.shares-screen .recommendations .recommendation-item-info .name {
  font-size: 28px;
  line-height: 28px;
  color: #000;
  font-family: "almoni-demibold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations .recommendation-item-info .name {
    font-size: 26px;
    line-height: 26px;
  }
}
.shares-screen .recommendations .recommendation-item-info .genre {
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #000;
}
.shares-screen .recommendations .recommendation-item-info .channels {
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  line-height: 11px;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.22px;
}
.shares-screen .recommendations .recommendation-item-info .channels .channel-item {
  position: relative;
  padding: 0 0 0 3px;
  white-space: nowrap;
}
.shares-screen .recommendations .recommendation-item-info .channels .channel-item::after {
  content: ",";
}
.shares-screen .recommendations .recommendation-item-info .channels .channel-item:last-child {
  padding: 0;
}
.shares-screen .recommendations .recommendation-item-info .channels .channel-item:last-child::after {
  content: "";
}
.shares-screen .recommendations .recommendation-item-info .empty-channels {
  font-size: 11px;
  line-height: 11px;
  color: #bb2000;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0.22px;
}
.shares-screen .recommendations .recommendation-item-info .empty-channels .error {
  margin-left: 4px;
}
.shares-screen .recommendations .recommendation-item-info .to-article-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations .recommendation-item-info .to-article-mobile {
    display: block;
    padding-top: 13px;
    padding-bottom: 2px;
    color: #387db8;
    font-size: 12px;
    line-height: 12px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-decoration: underline;
  }
}
.shares-screen .recommendations .recommendation-item-info .btn-skip {
  display: flex;
  width: 140px;
  height: 28px;
  padding: 0 14px 0 5px;
  margin-top: 15px;
  align-items: center;
  border-radius: 20px;
  border: solid 2px #ffba01;
  background-color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}
.shares-screen .recommendations .recommendation-item-info .btn-skip i {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
.shares-screen .recommendations .recommendation-item-info .btn-skip i svg {
  width: 100% !important;
  height: 100% !important;
}
.shares-screen .recommendations .recommendation-item .to-article {
  width: 105px;
  min-width: 105px;
  padding-top: 7px;
  margin-right: 31px;
}
@media only screen and (max-width: 767px) {
  .shares-screen .recommendations .recommendation-item .to-article {
    display: none;
  }
}
.shares-screen .recommendations .recommendation-item .to-article a {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.shares-screen .recommendations .recommendation-item .to-article a .btn {
  display: flex;
  width: 58px;
  height: 58px;
  margin-bottom: 5px;
  justify-content: space-around;
  align-items: center;
  background-color: #212f3b;
  border-radius: 29px;
}
.shares-screen .recommendations .recommendation-item .to-article a .btn i {
  display: block;
  width: 22px !important;
  height: 22px !important;
}
.shares-screen .recommendations .recommendation-item .to-article a .btn i svg {
  width: 100% !important;
  height: 100% !important;
}
.shares-screen .recommendations .recommendation-item .to-article a .text {
  font-size: 16px;
  line-height: 16px;
  color: #0e0e0e;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
}
.shares-screen .recommendations .recommendation:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.shares-screen .honeycombs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18vw;
  background-image: url("/assets/client/images/kaveret.svg");
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .shares-screen .honeycombs {
    display: none;
  }
}
.shares-screen .btn-to-main {
  width: 100%;
  max-width: 332px;
  padding: 13px 0 13px;
  margin: 54px auto 0;
  border-radius: 5px;
  background-color: #ffba01;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.33px;
  color: #101010;
  font-family: "almoni-demibold", sans-serif;
  text-align: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .shares-screen .btn-to-main {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.shares-screen .rate {
  position: relative;
  display: flex;
  width: 86px;
  min-width: 86px;
  padding-top: 7px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 767px) {
  .shares-screen .rate {
    width: 81px;
    min-width: 81px;
    flex-shrink: 0;
  }
}
.shares-screen .rate .dash-wrap {
  background-color: #f7b402;
  border-radius: 50%;
}
.shares-screen .rate .path-dash {
  fill: none;
  stroke: #323232;
  stroke-width: 6.96px;
  stroke-dasharray: 1.99 1;
}
.shares-screen .rate .fakeChart {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  transform: rotate(-90deg);
}
.shares-screen .rate .fakeChart svg .circle-inner {
  stroke: #f7b402;
  fill: transparent;
  stroke-width: 8px;
  stroke-dasharray: 314;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s;
}
.shares-screen .rate-inner {
  position: relative;
  display: flex;
  width: 58px;
  height: 58px;
  margin-bottom: 5px;
  justify-content: space-around;
  align-items: center;
  background-color: #f7b402;
  border-radius: 29px;
}
.shares-screen .rate-inner .rate-percents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #0e0e0e;
  font-family: "fb-reforma", sans-serif;
  font-weight: 700;
}
.shares-screen .rate-text {
  font-size: 16px;
  line-height: 16px;
  color: #0e0e0e;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .shares-screen .rate-text {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.28px;
  }
}