@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.rabbi-logo {
  position: absolute;
  bottom: 40px;
  left: 20px;
  z-index: 15;
  width: 20px;
  height: 28px;
  overflow: hidden;
  direction: ltr;
  transition: 0.25s ease;
}
@media screen and (min-width: 1024px) {
  .rabbi-logo:hover {
    width: 140px;
  }
}
@media only screen and (max-width: 767px) {
  .rabbi-logo {
    bottom: 27px;
    left: 12px;
  }
}
.rabbi-logo__link {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 140px;
  height: 28px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
}
@media only screen and (min-width: 767px) {
  .rabbi-logo__link:focus {
    outline: red auto 5px;
  }
}
.rabbi-logo__text {
  margin-top: -1px;
  margin-left: 7px;
  font-size: 10px;
  line-height: 11px;
  font-family: sans-serif;
  letter-spacing: initial;
}
.rabbi-logo__icon {
  display: inline-block;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rabbi-logo__icon {
    width: 20px;
  }
}
.rabbi-logo.dark {
  color: black;
}
.rabbi-logo.dark a {
  color: black;
}
.rabbi-logo.dark .r {
  fill: black;
}
.rabbi-logo.light {
  color: white;
}
.rabbi-logo.light a {
  color: white;
}
.rabbi-logo.light .r {
  fill: white;
}