@charset "UTF-8";
/* stylelint-disable order/order */
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.first-screen {
  position: relative;
  width: 100%;
  padding-bottom: 452px;
}
@media only screen and (max-width: 767px) {
  .first-screen {
    padding-bottom: 86px;
  }
}
.first-screen .top-section {
  position: relative;
  height: 425px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.first-screen .top-section .top-section-wrapper {
  position: relative;
  width: 600px;
  height: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .first-screen .top-section .top-section-wrapper {
    margin: initial;
  }
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper {
    width: 100%;
    padding-top: 18px;
    padding-right: 51px;
    padding-left: 17px;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 320px) {
  .first-screen .top-section .top-section-wrapper {
    padding-right: 35px;
  }
}
.first-screen .top-section .top-section-wrapper .head-title {
  position: absolute;
  top: 121px;
  right: 40px;
  width: 262px;
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper .head-title {
    position: relative;
    top: auto;
    right: auto;
    z-index: 2;
    width: 230px;
  }
}
@media only screen and (max-width: 320px) {
  .first-screen .top-section .top-section-wrapper .head-title {
    width: 240px;
  }
}
.first-screen .top-section .top-section-wrapper .head-title span {
  display: inline-block;
  padding: 0 4px;
  font-size: 93.3px;
  line-height: 0.71;
  background-color: #fff;
  font-family: "almoni-demibold", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper .head-title span {
    font-size: 65px;
    line-height: 0.74;
  }
}
.first-screen .top-section .top-section-wrapper .head-title span:last-child {
  margin-top: 3px;
}
.first-screen .top-section .top-section-wrapper .text {
  position: absolute;
  top: 198px;
  left: -107px;
  width: 492px;
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper .text {
    position: relative;
    top: auto;
    left: 0;
    z-index: 2;
    width: 100%;
    padding-top: 7px;
  }
}
.first-screen .top-section .top-section-wrapper .text p {
  font-size: 28px;
  line-height: 30px;
  font-family: "almoni-medium", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper .text p {
    font-size: 30px;
    line-height: 30px;
  }
}
.first-screen .top-section .top-section-wrapper .bee-animation {
  position: absolute;
  top: 13px;
  right: -112px;
  width: 1200px;
  height: 250px;
}
@media only screen and (max-width: 767px) {
  .first-screen .top-section .top-section-wrapper .bee-animation {
    top: -69px;
    right: -320px;
    z-index: 1;
    width: 1200px;
    height: 250px;
  }
}
.first-screen .items-list {
  display: flex;
  width: 100%;
  max-width: 971px;
  margin: -95px auto 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .first-screen .items-list {
    width: 660px;
  }
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list {
    max-width: 357px;
    margin: -160px auto 0;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 320px) {
  .first-screen .items-list {
    margin: -120px auto 0;
  }
}
.first-screen .items-list .item {
  position: relative;
  width: 313px;
  height: 209px;
  padding-bottom: 17px;
  margin-bottom: 11px;
  margin-left: 16px;
  transition: all ease 0.3s;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .first-screen .items-list .item:nth-child(3n+3) {
    margin-left: 16px;
  }
  .first-screen .items-list .item:nth-child(2n) {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item {
    width: 48%;
    height: 210px;
    margin-bottom: 18px;
    margin-left: 0;
  }
}
.first-screen .items-list .item::before {
  content: "";
  position: absolute;
  bottom: 17px;
  left: 0;
  z-index: 2;
  display: block;
  width: 100%;
  height: 85px;
  /* stylelint-disable */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05) 12%, rgba(0, 0, 0, 0.17) 23%, rgba(0, 0, 0, 0.35) 34%, rgba(0, 0, 0, 0.54) 46%, rgba(0, 0, 0, 0.73) 60%, rgba(0, 0, 0, 0.89) 78%, #000);
  /* stylelint-enable */
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item::before {
    width: 100%;
    height: 96px;
  }
}
.first-screen .items-list .item::after {
  content: "";
  position: absolute;
  bottom: 17px;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 6px;
  background-color: #ffba01;
  opacity: 0;
  transition: all ease 0.3s;
}
.first-screen .items-list .item:hover .item-like {
  background-color: #ffecbb;
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item:hover .item-like {
    background-color: #fff;
  }
}
.first-screen .items-list .item:nth-child(3n+3) {
  margin-left: 0;
}
.first-screen .items-list .item.selected::after {
  opacity: 1;
}
.first-screen .items-list .item.selected .item-like {
  background-color: #ffba01;
}
.first-screen .items-list .item-image {
  width: 100%;
  height: 100%;
}
.first-screen .items-list .item-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.first-screen .items-list .item-info {
  position: absolute;
  bottom: 36px;
  left: 50%;
  z-index: 3;
  width: 90%;
  transform: translate(-50%, 0%);
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item-info {
    bottom: 40px;
    width: 77%;
  }
}
.first-screen .items-list .item-info .name {
  width: 100%;
  font-size: 18px;
  line-height: 1.38;
  color: #fff;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item-info .name {
    font-size: 16px;
    line-height: 1.25;
  }
}
.first-screen .items-list .item-info .credit {
  width: 100%;
  font-size: 10px;
  line-height: 2.2;
  color: #fff;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item-info .credit {
    display: none;
  }
}
.first-screen .items-list .item-like {
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: 5;
  display: flex;
  width: 108px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: #000;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.19);
  border: solid 2px #ffba01;
  background-color: #fff;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item-like {
    bottom: -18px;
  }
}
.first-screen .items-list .item-like i {
  width: 25px;
}
.first-screen .items-list .item-mobile-credit {
  display: none;
}
@media only screen and (max-width: 767px) {
  .first-screen .items-list .item-mobile-credit {
    position: absolute;
    bottom: 36px;
    left: -6px;
    z-index: 2;
    display: block;
    font-size: 10px;
    line-height: 2.2;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    transform: rotate(-90deg) translateY(5px) translateZ(0);
    transform-origin: left top;
  }
}
.first-screen .items-list .item-mobile-credit p {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  overflow: hidden;
  transform: rotate(180deg);
  text-overflow: ellipsis;
  white-space: nowrap;
}
.first-screen .texts {
  width: 100%;
  max-width: 680px;
  padding-top: 105px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts {
    max-width: inherit;
    padding: 0 16px;
    padding-top: 220px;
  }
}
.first-screen .texts .title {
  display: inline-block;
  padding: 0 4px;
  margin-bottom: 45px;
  background-color: #ffba01;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-size: 28px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts .title {
    padding: 2px 8px;
    margin-bottom: 18px;
  }
}
.first-screen .texts h3 {
  padding-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000;
}
.first-screen .texts p {
  padding-bottom: 43px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts p {
    padding-bottom: 42px;
    font-size: 18px;
    line-height: 24px;
  }
}
.first-screen .texts .list .item {
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts .list .item {
    padding-bottom: 20px;
  }
}
.first-screen .texts .list .item span {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 27px;
  color: #000;
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts .list .item span {
    font-size: 18px;
    line-height: 24px;
  }
}
.first-screen .texts .list .item span b {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.first-screen .texts .list .item p {
  padding-bottom: 0;
  letter-spacing: -0.3px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 27px;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts .list .item p {
    font-size: 18px;
    line-height: 24px;
  }
}
.first-screen .texts .btn-to-top {
  width: 265px;
  padding: 8px 0 10px;
  margin: 30px auto 0;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  color: #212f3b;
  font-family: "almoni-demibold", sans-serif;
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .first-screen .texts .btn-to-top {
    width: 100%;
    max-width: 168px;
    padding: 12px 0 6px;
    margin: 30px auto 30px;
    font-size: 21px;
  }
}
.first-screen .texts .btn-to-top:hover {
  text-decoration: none;
}
.first-screen .honeycombs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 452px;
  background-image: url("/assets/client/images/kaveret.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .first-screen .honeycombs {
    display: none;
  }
}
.first-screen .progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 124px;
  justify-content: space-between;
  align-items: center;
  transform: translate(0%, 0%);
  background-color: #212f3b;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar {
    height: 86px;
  }
}
.first-screen .progress-bar .progress-bar-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 680px;
  margin: 0 auto;
  align-items: center;
}
.first-screen .progress-bar .right-part {
  width: 35%;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar .right-part {
    width: 50%;
    padding-right: 16px;
  }
}
.first-screen .progress-bar .right-part .checked-items-counter {
  font-size: 30px;
  line-height: 33px;
  color: #fff;
  letter-spacing: 1px;
  font-family: "almoni-demibold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar .right-part .checked-items-counter {
    font-size: 22px;
    line-height: 1.11;
    letter-spacing: 0.21px;
    text-align: center;
  }
}
.first-screen .progress-bar .right-part .additional-text {
  font-size: 26px;
  line-height: 33px;
  color: #fff;
  letter-spacing: 1px;
  font-family: "almoni-regular", sans-serif;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar .right-part .additional-text {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: center;
  }
}
.first-screen .progress-bar .left-part {
  display: flex;
  width: 65%;
  justify-content: space-around;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar .left-part {
    width: 50%;
    padding-left: 45px;
  }
}
.first-screen .progress-bar .left-part .btn {
  width: 265px;
  height: 51px;
  padding: 8px 0 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffba01;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  color: #212f3b;
  font-family: "almoni-demibold", sans-serif;
  cursor: pointer;
  border: 2px solid #ffba01;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .first-screen .progress-bar .left-part .btn {
    width: 100%;
    max-width: 168px;
    padding: 12px 0 6px;
    font-size: 21px;
  }
}
.first-screen .progress-bar .left-part .btn:hover {
  background-color: #fff;
}
.first-screen .progress-bar .left-part .btn.disabled {
  border: 2px solid #7e96ab;
  background-color: #7e96ab;
  cursor: default;
}