@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 81px;
  overflow-x: clip;
  overflow-y: visible;
  background-color: #22303b;
}
@media only screen and (max-width: 767px) {
  .header {
    height: 60px;
  }
}
.header .header-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1410px;
  padding: 0 5px 0 10px;
  margin: 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  direction: rtl;
}
@media only screen and (max-width: 767px) {
  .header .header-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 17px 0 0;
  }
}

.left-side {
  display: flex;
  align-items: center;
}
.left-side .logo {
  display: block;
  width: 81px;
  margin-left: 37px;
}
@media only screen and (max-width: 767px) {
  .left-side .logo {
    display: none;
  }
}
.left-side .logo img {
  display: block;
  width: 100%;
}
.left-side .back-btn-wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.24px;
  cursor: pointer;
}
.left-side .back-btn-wrapper .back-btn {
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
}
@media only screen and (max-width: 767px) {
  .left-side .back-btn-wrapper .back-btn {
    width: 24px;
    height: 24px;
    margin-right: 7px;
    margin-left: 12px;
  }
}
.left-side .back-btn-wrapper .back-btn i {
  width: 18px;
}
.left-side .back-btn-wrapper .back-btn i svg {
  width: 100% !important;
}

.right-side {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.right-side .tv-bee-logo {
  display: block;
  width: 99px;
}
@media only screen and (max-width: 767px) {
  .right-side .tv-bee-logo {
    width: 72px;
  }
}
.right-side .tv-bee-logo img {
  display: block;
  width: 100%;
}