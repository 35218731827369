@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.second-screen {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 452px;
}
@media only screen and (max-height: 720px) {
  .second-screen {
    padding-bottom: 153px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen {
    min-height: 100vh;
    padding-top: 60px;
    padding-bottom: 130px;
  }
}
.second-screen .top-section {
  position: relative;
  height: 369px;
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (max-height: 720px) {
  .second-screen .top-section {
    height: 339px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section {
    height: 245px;
  }
}
.second-screen .top-section .top-section-wrapper {
  position: relative;
  width: 600px;
  height: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper {
    width: 100%;
    padding-top: 18px;
    padding-right: 51px;
    padding-left: 17px;
  }
}
.second-screen .top-section .top-section-wrapper .head-title {
  position: absolute;
  top: 121px;
  right: 55px;
  width: 300px;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper .head-title {
    position: relative;
    top: auto;
    right: auto;
    z-index: 2;
    width: 230px;
  }
}
.second-screen .top-section .top-section-wrapper .head-title span {
  display: inline-block;
  padding: 0 4px;
  font-size: 93.3px;
  line-height: 0.71;
  background-color: #fff;
  font-family: "almoni-demibold", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper .head-title span {
    font-size: 65px;
    line-height: 0.74;
  }
}
.second-screen .top-section .top-section-wrapper .head-title span:last-child {
  margin-top: 3px;
}
.second-screen .top-section .top-section-wrapper .text {
  position: absolute;
  top: 198px;
  left: -130px;
  width: 492px;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper .text {
    position: relative;
    top: auto;
    left: 0;
    z-index: 2;
    width: 100%;
    padding-top: 7px;
  }
}
.second-screen .top-section .top-section-wrapper .text p {
  font-size: 28px;
  line-height: 30px;
  font-family: "almoni-medium", sans-serif;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper .text p {
    font-size: 30px;
    line-height: 30px;
  }
}
.second-screen .top-section .top-section-wrapper .bee-animation {
  position: absolute;
  top: 13px;
  right: -112px;
  width: 1200px;
  height: 250px;
}
@media only screen and (max-width: 767px) {
  .second-screen .top-section .top-section-wrapper .bee-animation {
    top: -69px;
    right: -300px;
    z-index: 1;
    width: 1200px;
    height: 250px;
  }
}
.second-screen .channels {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels {
    display: none;
  }
}
@media only screen and (max-height: 700px) {
  .second-screen .channels {
    margin-top: -20px;
  }
}
.second-screen .channels .channels-row {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.second-screen .channels .channels-row:first-child {
  max-width: 798px;
}
.second-screen .channels .channels-row:nth-child(2) {
  max-width: 652px;
}
.second-screen .channels .channels-row:last-child {
  max-width: 798px;
}
.second-screen .channels .channels-row .channel-box:nth-child(5n+5) {
  margin-left: 0;
}
.second-screen .channels .channel-box {
  position: relative;
  display: flex;
  width: 146px;
  height: 34px;
  margin-bottom: 23px;
  margin-left: 17px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #fff;
  border: solid 2px #ffba01;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.3s;
}
@media only screen and (max-height: 720px) {
  .second-screen .channels .channel-box {
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen .channels .channel-box {
    margin-bottom: 18px;
  }
}
.second-screen .channels .channel-box:hover {
  background-color: #ffecbb;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels .channel-box:hover {
    background-color: #fff;
  }
}
.second-screen .channels .channel-box:nth-child(5n+5) {
  margin-left: 0;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels .channel-box:nth-child(2n+2) {
    margin-left: 0;
  }
}
.second-screen .channels .channel-box.selected {
  background-color: #ffba01;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels .channel-box.selected {
    border: solid 2px #fbf9f7;
  }
}
.second-screen .channels .channel-box .image {
  display: block;
}
.second-screen .channels .channel-box .image.yes {
  width: 35px;
}
.second-screen .channels .channel-box .image.hot {
  width: 20px;
}
.second-screen .channels .channel-box .image.cellcom {
  width: 78px;
}
.second-screen .channels .channel-box .image.netflix {
  width: 55px;
}
.second-screen .channels .channel-box .image.peacock {
  width: 61px;
}
.second-screen .channels .channel-box .image.prime {
  width: 63px;
}
.second-screen .channels .channel-box .image.hbo {
  width: 64px;
}
.second-screen .channels .channel-box .image.apple {
  width: 91px;
}
.second-screen .channels .channel-box .image.partner {
  width: 96px;
}
.second-screen .channels .channel-box .image.hulu {
  width: 38px;
}
.second-screen .channels .channel-box .image.disney {
  width: 46px;
}
.second-screen .channels .channel-box .image.paramoumt {
  width: 74px;
}
.second-screen .channels .channel-box .image.discovery-plus {
  width: 98px;
}
.second-screen .channels .channel-box .image.freeTv {
  width: 35px;
}
.second-screen .channels .channel-box p {
  font-size: 18px;
  color: #000;
}
.second-screen .channels-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels-mobile {
    display: flex;
    width: 100%;
    max-width: 309px;
    margin: -50px auto 0;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media only screen and (max-height: 700px) {
  .second-screen .channels-mobile {
    margin-top: -20px;
  }
}
.second-screen .channels-mobile .channel-box {
  position: relative;
  display: flex;
  width: 146px;
  height: 34px;
  margin-bottom: 23px;
  margin-left: 17px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #fff;
  border: solid 2px #ffba01;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.3s;
}
@media only screen and (max-height: 720px) {
  .second-screen .channels-mobile .channel-box {
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen .channels-mobile .channel-box {
    margin-bottom: 18px;
  }
}
.second-screen .channels-mobile .channel-box:hover {
  background-color: #ffecbb;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels-mobile .channel-box:hover {
    background-color: #fff;
  }
}
.second-screen .channels-mobile .channel-box:nth-child(4n+4) {
  margin-left: 0;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels-mobile .channel-box:nth-child(2n+2) {
    margin-left: 0;
  }
}
.second-screen .channels-mobile .channel-box.selected {
  background-color: #ffba01;
}
@media only screen and (max-width: 767px) {
  .second-screen .channels-mobile .channel-box.selected {
    border: solid 2px #fbf9f7;
  }
}
.second-screen .channels-mobile .channel-box .image {
  display: block;
}
.second-screen .channels-mobile .channel-box .image.yes {
  width: 35px;
}
.second-screen .channels-mobile .channel-box .image.hot {
  width: 20px;
}
.second-screen .channels-mobile .channel-box .image.cellcom {
  width: 78px;
}
.second-screen .channels-mobile .channel-box .image.netflix {
  width: 55px;
}
.second-screen .channels-mobile .channel-box .image.peacock {
  width: 61px;
}
.second-screen .channels-mobile .channel-box .image.prime {
  width: 63px;
}
.second-screen .channels-mobile .channel-box .image.hbo {
  width: 64px;
}
.second-screen .channels-mobile .channel-box .image.apple {
  width: 91px;
}
.second-screen .channels-mobile .channel-box .image.partner {
  width: 96px;
}
.second-screen .channels-mobile .channel-box .image.hulu {
  width: 38px;
}
.second-screen .channels-mobile .channel-box .image.disney {
  width: 46px;
}
.second-screen .channels-mobile .channel-box .image.paramoumt {
  width: 74px;
}
.second-screen .channels-mobile .channel-box .image.discovery-plus {
  width: 98px;
}
.second-screen .channels-mobile .channel-box .image.freeTv {
  width: 35px;
}
.second-screen .channels-mobile .channel-box p {
  font-size: 18px;
  color: #000;
}
.second-screen .honeycombs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 452px;
  background-image: url("/assets/client/images/kaveret.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}
@media only screen and (max-height: 720px) {
  .second-screen .honeycombs {
    height: 153px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen .honeycombs {
    display: none;
  }
}
.second-screen .bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 124px;
  justify-content: space-between;
  align-items: center;
  transform: translate(0%, 0%);
  background-color: #212f3b;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar {
    height: 86px;
  }
}
.second-screen .bottom-bar-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 680px;
  margin: 0 auto;
  align-items: center;
}
.second-screen .bottom-bar .right-part {
  width: 35%;
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar .right-part {
    width: 50%;
    padding-right: 16px;
  }
}
.second-screen .bottom-bar .right-part p {
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 1px;
  color: #fff;
  font-family: "almoni-demibold", sans-serif;
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar .right-part p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.21px;
  }
}
.second-screen .bottom-bar .left-part {
  display: flex;
  width: 65%;
  justify-content: space-around;
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar .left-part {
    width: 50%;
    padding-left: 45px;
  }
}
.second-screen .bottom-bar .left-part .btn {
  width: 265px;
  height: 51px;
  padding: 8px 0 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffba01;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  color: #212f3b;
  font-family: "almoni-demibold", sans-serif;
  cursor: pointer;
  border: 2px solid #ffba01;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar .left-part .btn {
    width: 100%;
    max-width: 168px;
    padding: 12px 0 6px;
    font-size: 21px;
  }
}
.second-screen .bottom-bar .left-part .btn:hover {
  background-color: #fff;
}
.second-screen .bottom-bar .left-part .btn.disabled {
  background-color: #7e96ab;
  border: 2px solid #7e96ab;
  cursor: default;
}
.second-screen .bottom-bar .skip {
  position: absolute;
  top: -60px;
  left: 50%;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.43px;
  color: #000;
  opacity: 0.6;
  font-family: "almoni-demibold", sans-serif;
  transform: translate(-50%, 0%);
  cursor: pointer;
}
@media only screen and (max-height: 700px) {
  .second-screen .bottom-bar .skip {
    top: -40px;
  }
}
@media only screen and (max-width: 767px) {
  .second-screen .bottom-bar .skip {
    display: none;
    white-space: nowrap;
  }
}
.second-screen .bottom-bar .skip::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  opacity: 0.6;
}
.second-screen .bottom-bar .skip:hover {
  text-decoration: none;
}
.second-screen .skip-mobile {
  position: relative;
  display: none;
}
@media only screen and (max-width: 767px) {
  .second-screen .skip-mobile {
    display: block;
    padding-top: 15px;
    white-space: nowrap;
  }
}
.second-screen .skip-mobile .skip-mobile-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.43px;
  color: #000;
  opacity: 0.6;
  font-family: "almoni-demibold", sans-serif;
  cursor: pointer;
}
.second-screen .skip-mobile .skip-mobile-wrapper::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  opacity: 0.6;
}