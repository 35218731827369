@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value fadeInOut: 350ms;
:global .anticon-close-circle-o {
  display: none;
}
:global .ant-notification-notice-close {
  color: rgb(158, 20, 20);
  font-size: 20px;
}
:global .ant-notification-notice-description {
  margin-top: 10px;
}
:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {
  margin-bottom: 0;
}
:global body {
  color: #fff;
}

.app {
  margin: 0 auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .app {
    height: 100%;
  }
}
.app[data-desktop=true] {
  padding-top: 12px;
}
.app[data-title=true] {
  padding-top: 0;
}

.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.pop-up-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.pop-up-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: flex;
  width: 400px;
  min-height: 100px;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: #212f3b;
  color: white;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .pop-up-content {
    width: 300px;
  }
}
.pop-up-content p {
  font-size: 16px;
  font-weight: 700;
}
.pop-up-content button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  padding: 10px;
}
.pop-up-content button::after, .pop-up-content button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: white;
}
.pop-up-content button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.pop-up-content button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.content {
  position: relative;
  min-height: 100vh;
  background-color: #fbf9f7;
}
@media only screen and (max-width: 767px) {
  .content {
    width: 100%;
    height: 100%;
    min-height: 0;
    margin: 0 auto;
  }
}

.clientPreloader {
  position: absolute;
}

.overlay-a {
  position: fixed;
  bottom: 12px;
  z-index: 666;
  width: 100%;
  height: 80px;
  padding-right: 20px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  pointer-events: none;
}
.overlay-b {
  z-index: 555;
  width: 100%;
  height: 80px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(250, 250, 250, 0.5215686275));
}
.overlay-c {
  position: fixed;
  bottom: 0;
  z-index: 555;
  width: 100%;
  height: 12px;
  background-image: linear-gradient(#edf1f5, #edf1f5);
}

p {
  margin-bottom: 0;
}

.fade-enter, .fade-appear {
  opacity: 0;
  visibility: hidden;
}
.fade-appear-active, .fade-enter-active {
  opacity: 1;
  visibility: visible;
  transition: opacity, visibility;
  transition-duration: fadeInOut;
}
.fade-exit-active {
  opacity: 0;
  visibility: hidden;
  transition: opacity, visibility;
  transition-duration: fadeInOut;
}